.single-product-container {
  padding: 20px;
  background-color: white;
  height: max-content;
}

.single-product-ad {
  width: 100%;
  margin-bottom: 10px;
}
.single-product {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.single-product-image {
  object-fit: contain;
  width: 300px;
  height: max-content;
}

.single-product-info {
  padding-left: 20px;
}

.single-product-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.single-product-rating {
  margin-top: 10px;
  display: flex;
}

.single-product-price {
  margin-top: 10px;
  color: #000;
}

.single-product-info > button {
  background: #000;
  border: 1px solid;
  margin-top: 40px;
  min-width: 150px;
  color: white;
  border-color: #a88734 #9c7e31 #846a29;
  cursor: pointer;
}

.single-product-description {
  margin-top: 20px;
  color: #000;
}

.single-product-specification {
  margin-top: 20px;
  color: #000;
}

i {
  vertical-align: middle;
}
